export const groupRawData = [
    ["3187", "1"],
    ["3314", "1"],
    ["3334", "1"],
    ["1965", "1"],
    ["2179", "1"],
    ["3188", "1"],
    ["2180", "1"],
    ["1067", "1"],
    ["296", "1"],
    ["2181", "1"],
    ["3335", "1"],
    ["998", "1"],
    ["2127", "1"],
    ["954", "1"],
    ["1646", "1"],
    ["3333", "1"],
    ["3313", "1"],
    ["3373", "1"],
    ["3499", "1"],
    ["2101", "1"],
    ["3312", "1"],
    ["216", "2"],
    ["1510", "2"],
    ["3348", "2"],
    ["3349", "2"],
    ["1516", "2"],
    ["3397", "2"],
    ["3350", "2"],
    ["1466", "2"],
    ["3185", "3"],
    ["3394", "3"],
    ["2131", "3"],
    ["2143", "3"],
    ["3405", "3"],
    ["1904", "3"],
    ["3221", "3"],
    ["3218", "3"],
    ["1902", "3"],
    ["2183", "3"],
    ["2190", "3"],
    ["3223", "3"],
    ["2158", "3"],
    ["1425", "3"],
    ["1040", "3"],
    ["3436", "3"],
    ["2159", "3"],
    ["3502", "3"],
    ["3235", "3"],
    ["2160", "3"],
    ["2184", "3"],
    ["2177", "3"],
    ["3340", "3"],
    ["3228", "3"],
    ["3237", "3"],
    ["3419", "3"],
    ["3503", "3"],
    ["2182", "3"],
    ["1984", "3"],
    ["3224", "3"],
    ["1797", "3"],
    ["2185", "3"],
    ["1905", "3"],
    ["485", "3"],
    ["3493", "3"],
    ["3183", "3"],
    ["3238", "3"],
    ["3311", "3"],
    ["2188", "3"],
    ["3474", "3"],
    ["2186", "3"],
    ["3508", "3"],
    ["1470", "3"],
    ["3352", "3"],
    ["2187", "3"],
    ["3305", "3"],
    ["2192", "3"],
    ["365", "4"],
    ["2166", "4"],
    ["2157", "4"],
    ["3298", "4"],
    ["3245", "4"],
    ["3246", "4"],
    ["3247", "4"],
    ["3248", "4"],
    ["3249", "4"],
    ["3263", "4"],
    ["2164", "4"],
    ["3501", "4"],
    ["3251", "4"],
    ["2163", "4"],
    ["3252", "4"],
    ["3253", "4"],
    ["3254", "4"],
    ["3256", "4"],
    ["3257", "4"],
    ["3192", "4"],
    ["3258", "4"],
    ["2161", "4"],
    ["3477", "4"],
    ["3259", "4"],
    ["3310", "4"],
    ["3260", "4"],
    ["1563", "4"],
    ["3261", "4"],
    ["3480", "4"],
    ["3399", "4"],
    ["3262", "4"],
    ["3231", "4"],
    ["1927", "B"],
    ["3264", "B"],
    ["3229", "B"],
    ["3239", "B"],
    ["3234", "B"],
    ["3230", "B"],
    ["3241", "B"],
    ["105", "B"],
    ["3242", "B"],
    ["3232", "B"],
    ["3265", "B"],
    ["1932", "N"],
    ["1973", "N"],
    ["3438", "N"],
    ["3299", "N"],
    ["2136", "N"],
    ["3415", "N"],
    ["1578", "N"],
    ["1933", "N"],
    ["1796", "N"],
    ["3337", "N"],
    ["1549", "N"],
    ["1433", "N"],
    ["3343", "N"],
    ["3445", "N"],
    ["1898", "N"],
    ["2175", "N"],
    ["3309", "N"],
    ["1365", "N"],
    ["959", "N"],
    ["2169", "N"],
    ["3442", "N"],
    ["3409", "N"],
    ["3454", "N"],
    ["3422", "N"],
    ["3389", "N"],
    ["3453", "N"],
    ["1399", "N"],
    ["3344", "N"],
    ["2074", "N"],
    ["1893", "N"],
    ["2049", "N"],
    ["3217", "N"],
    ["31", "N"],
    ["3387", "N"],
    ["1916", "N"],
    ["3296", "N"],
    ["3449", "N"],
    ["1542", "N"],
    ["818", "N"],
    ["41", "N"],
    ["1480", "N"],
    ["942", "N"],
    ["3338", "N"],
    ["1581", "N"],
    ["145", "N"],
    ["3437", "N"],
    ["3505", "N"],
    ["3341", "N"],
    ["1027", "N"],
    ["1543", "N"],
    ["2152", "N"],
    ["3377", "N"],
    ["1373", "N"],
    ["1956", "N"],
    ["1402", "N"],
    ["2172", "N"],
    ["3346", "N"],
    ["1931", "N"],
    ["2017", "N"],
    ["3443", "N"],
    ["2018", "N"],
    ["1504", "N"],
    ["2011", "N"],
    ["1410", "N"],
    ["1895", "N"],
    ["1474", "N"],
    ["3267", "N"],
    ["1409", "N"],
    ["1378", "N"],
    ["3362", "N"],
    ["3469", "N"],
    ["3433", "N"],
    ["2162", "N"],
    ["3434", "N"],
    ["1527", "N"],
    ["1523", "N"],
    ["3336", "N"],
    ["3420", "N"],
    ["2145", "N"],
    ["1426", "N"],
    ["3402", "N"],
    ["3444", "N"],
    ["1935", "N"],
    ["3487", "N"],
    ["2138", "N"],
    ["3300", "N"],
    ["1729", "N"],
    ["3391", "N"],
    ["3500", "N"],
    ["3364", "N"],
    ["137", "N"],
    ["3214", "N"],
    ["821", "N"],
    ["1689", "N"],
    ["204", "N"],
    ["3467", "N"],
    ["3370", "N"],
    ["688", "N"],
    ["203", "N"],
    ["3219", "N"],
    ["396", "N"],
    ["293", "N"],
    ["514", "N"],
    ["3357", "N"],
    ["135", "N"],
    ["2051", "N"],
    ["3361", "N"],
    ["2146", "N"],
    ["3430", "N"],
    ["1907", "N"],
    ["3392", "N"],
    ["1770", "N"],
    ["1481", "N"],
    ["1484", "N"],
    ["1990", "N"],
    ["2171", "N"],
    ["1582", "N"],
    ["1545", "N"],
    ["2147", "N"],
    ["829", "N"],
    ["533", "N"],
    ["3227", "N"],
    ["2139", "N"],
    ["3489", "X"],
    ["1539", "N"],
    ["3306", "N"],
    ["2173", "N"],
    ["3383", "N"],
    ["201", "N"],
    ["1746", "N"],
    ["2148", "N"],
    ["3495", "N"],
    ["1975", "N"],
    ["374", "N"],
    ["205", "N"],
    ["3427", "N"],
    ["2174", "N"],
    ["1540", "N"],
    ["1722", "N"],
    ["2170", "N"],
    ["3458", "N"],
    ["3485", "N"],
    ["3416", "N"],
    ["2149", "N"],
    ["1507", "N"],
    ["387", "N"],
    ["3428", "N"],
    ["1528", "N"],
    ["1370", "N"],
    ["3356", "N"],
    ["3384", "N"],
    ["2150", "N"],
    ["3301", "N"],
    ["301", "N"],
    ["211", "N"],
    ["1508", "N"],
    ["24", "N"],
    ["48", "N"],
    ["1384", "N"],
    ["1458", "N"],
    ["3429", "N"],
    ["2167", "N"],
    ["3345", "N"],
    ["837", "N"],
    ["773", "N"],
    ["489", "N"],
    ["210", "N"],
    ["1461", "N"],
    ["919", "N"],
    ["3513", "N"],
    ["1448", "N"],
    ["104", "N"],
    ["3504", "N"],
    ["709", "N"],
    ["2050", "N"],
    ["3215", "N"],
    ["2176", "N"],
    ["3303", "N"],
    ["665", "N"],
    ["1900", "N"],
    ["1985", "N"],
    ["3462", "N"],
    ["3375", "N"],
    ["3365", "N"],
    ["3431", "N"],
    ["3439", "N"],
    ["3404", "N"],
    ["3400", "N"],
    ["3358", "N"],
    ["3359", "N"],
    ["3268", "N"],
    ["3385", "N"],
    ["3459", "N"],
    ["3393", "N"],
    ["3488", "N"],
    ["3369", "N"],
    ["1431", "N"],
    ["173", "N"],
    ["3220", "N"],
    ["3353", "N"],
    ["334", "N"],
    ["1987", "N"],
    ["3426", "N"],
    ["3398", "N"],
    ["315", "N"],
    ["1925", "N"],
    ["3354", "N"],
    ["3506", "N"],
    ["3413", "N"],
    ["451", "N"],
    ["379", "N"],
    ["3304", "N"],
    ["2153", "N"],
    ["779", "N"],
    ["1385", "N"],
    ["3456", "N"],
    ["604", "N"],
    ["2026", "N"],
    ["3471", "N"],
    ["3401", "N"],
    ["3481", "N"],
    ["3451", "N"],
    ["63", "N"],
    ["207", "N"],
    ["1537", "N"],
    ["2144", "N"],
    ["2178", "N"],
    ["1044", "N"],
    ["843", "N"],
    ["3367", "N"],
    ["3418", "N"],
    ["82", "N"],
    ["3222", "N"],
    ["2154", "N"],
    ["3295", "N"],
    ["810", "N"],
    ["345", "N"],
    ["3368", "N"],
    ["187", "N"],
    ["3403", "N"],
    ["2141", "N"],
    ["2155", "N"],
    ["1773", "N"],
    ["1671", "N"],
    ["1778", "N"],
    ["3423", "N"],
    ["1645", "X"],
    ["3457", "X"],
    ["2112", "X"],
    ["2116", "X"],
    ["2103", "X"],
    ["3363", "X"],
    ["3351", "X"],
    ["2087", "X"],
    ["3266", "X"],
    ["3209", "X"],
    ["2134", "X"],
    ["1069", "X"],
    ["2107", "X"],
    ["2059", "X"],
    ["3316", "X"],
    ["3406", "X"],
    ["2110", "X"],
    ["2111", "X"],
    ["2108", "X"],
    ["2010", "X"],
    ["1551", "X"],
    ["1565", "X"],
    ["3342", "X"],
    ["2060", "X"],
    ["2078", "X"],
    ["2077", "X"],
    ["3374", "X"],
    ["2156", "X"],
    ["2121", "X"],
    ["2135", "X"],
    ["2117", "X"],
    ["3376", "X"],
    ["3396", "X"],
    ["3479", "X"],
    ["3417", "X"],
    ["1553", "X"],
    ["3414", "X"],
    ["2123", "X"],
    ["2118", "X"],
    ["3360", "N"],
    ["3315", "X"],
    ["3475", "X"],
    ["3388", "X"],
    ["3210", "X"],
    ["2055", "X"],
    ["2076", "X"],
    ["3441", "X"],
    ["2109", "X"],
    ["2095", "X"],
    ["3225", "X"],
    ["2142", "X"],
    ["1536", "X"],
    ["3332", "X"],
    ["2113", "X"],
    ["3452", "X"],
    ["3410", "X"],
    ["3478", "X"],
    ["3507", "X"],
    ["3446", "X"],
    ["3390", "X"],
    ["3407", "X"],
    ["1986", "X"],
    ["3216", "X"],
    ["1926", "X"],
    ["3339", "X"],
    ["2099", "X"],
    ["3297", "X"],
    ["3372", "X"],
    ["3371", "X"],
    ["808", "X"],
    ["3476", "X"],
    ["1896", "X"],
    ["3408", "X"],
    ["2080", "X"],
    ["2106", "X"],
    ["3511", "X"],
    ["2124", "X"],
    ["2098", "X"],
    ["2122", "X"],
    ["3473", "N"],
    ["3512", "N"],
    ["3411", "N"],
    ["3483", "N"],
    ["3510", "X"],
    ["3421", "N"],
    ["3450", "3"],
    ["836", "N"],
    ["3466", "N"],
    ["2119", "X"],
    ["2120", "X"],
    ["3514", "N"],
    ["3509", "N"],
    ["78", "N"],
    ["781", "B"],
    ["3517", "X"],
    ["3518", "N"],
    ["3525", "X"],
    ["3490", "N"],
    ["3519", "N"],
    ["3494", "N"],
    ["3521", "N"],
    ["3523", "2"],
    ["543", "N"],
    ["3528", "X"],
    ["3529", "X"],
    ["3531", "3"],
    ["3515", "1"],
    ["3522", "N"],
    ["3524", "N"],
    ["3530", "N"],
    ["931", "N"],
    ["3432", "N"],
    ["3532", "N"],
    ["3464", "N"],
    ["3533", "N"],
    ["3534", "X"],
    ["3535", "N"],
    ["3520", "N"],
    ["3539", "N"],
    ["1562", "N"],
    ["3486", "N"],
    ["575", "N"],
    ["3540", "X"],
    ["3538", "N"],
    ["3543", "X"],
    ["3545", "N"],
    ["3536", "N"],
    ["3526", "X"],
    ["1541", "N"],
    ["3547", "N"],
    ["3424", "N"],
    ["3541", "X"],
    ["3412", "N"],
    ["3548", "N"],
    ["36", "N"],
    ["3546", "X"],
    ["3542", "X"],
    ["37", "N"],
    ["140", "3"],
    ["102", "N"],
    ["3482", "N"],
    ["3549", "N"]
];
